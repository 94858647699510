import { gql } from '@apollo/client';
import { Box, IconButton } from '@chakra-ui/react';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { useEffect, useState } from 'react';
import { MdHelpOutline } from 'react-icons/md';
import { useChannelTalkButtonSuspenseQuery } from './ChannelTalkButton.generated';

gql`
query ChannelTalkButton {
  me {
    id
    channelTalkMemberHash
    name
    email
    company {
      id
      name
    }
  }
}
`;

export default function ChannelTalkButton() {
  const { data } = useChannelTalkButtonSuspenseQuery();
  const channelTalkEnabled = import.meta.env.VITE_CHANNEL_TALK_ENABLED_COMPANY_IDS.split(
    ','
  ).includes(data.me.company.id.toString());
  const [messageCount, setMessageCount] = useState(0);

  useEffect(() => {
    if (!channelTalkEnabled) {
      return;
    }
    ChannelService.loadScript();
    ChannelService.onBadgeChanged((count: number) => {
      setMessageCount(count);
    });
    const { me } = data;
    ChannelService.boot({
      pluginKey: import.meta.env.VITE_CHANNEL_TALK_PLUGIN_KEY,
      memberId: me.id,
      memberHash: me.channelTalkMemberHash,
      hideChannelButtonOnBoot: true,
      profile: {
        name: me.name,
        email: me.email,
        companyName: me.company.name,
      },
    });
  }, [data, channelTalkEnabled]);

  if (!channelTalkEnabled) {
    return null;
  }

  return (
    <IconButton
      aria-label='Help'
      fontSize='1.5em'
      onClick={() => ChannelService.showMessenger()}
      icon={
        <>
          <MdHelpOutline />
          {messageCount > 0 && (
            <Box
              as='span'
              color='white'
              fontSize='xs'
              lineHeight='1rem'
              bgColor='red'
              position='absolute'
              px={1}
              h='1rem'
              right='3px'
              borderRadius='9999px'
              top={1}
              zIndex={100}
            >
              {messageCount}
            </Box>
          )}
        </>
      }
    />
  );
}
